"use strict";
import modernizr from 'modernizr';

! function(e, t, i) {
 var n = function(t) {
  this.$element = e(t), this.$videoEl = this.$element.find("video,iframe"), this.videoType = this.$videoEl.attr("data-videotype"), this.$window = e(i), this.isInTool = document.body.hasAttribute("data-istool"), this.videoInterval = !1, this.vimeoInterval = !1, this.playTracked = !1, this.init()
 };
 n.prototype = {
  init: function() {
   if (this.isInTool || !i.HTMLVideoElement) return !1;
   var e = "add" + (this.videoType.charAt(0).toUpperCase() + this.videoType.slice(1)) + "Events";
   this[e]()
  },
  addVideoEvents: function() {
   var e = this;
   this.videoInterval = setInterval(function() {
    e.$videoEl[0].currentTime > 0 && (i._mtr && i._mtr.track("video_play"), clearInterval(e.videoInterval))
   }, 500)
  },
  addVimeoEvents: function() {
   var e = this;
   this.onVimeoAPIReady(function() {
    var t = $f(e.$videoEl[0]);
    t.addEvent("ready", function(e) {
     $f(e).addEvent("play", function() {
      i._mtr && i._mtr.track("video_play"), $f(e).removeEvent("play")
     })
    })
   })
  },
  addYoutubeEvents: function() {
   var e = this;
   i.YTReady || (i.YTReady = []), i.YTReady.push(function() {
    var t = new YT.Player(e.$videoEl[0], {
     events: {
      onStateChange: function(n) {
       1 != n.data || e.playTracked || (e.playTracked = !0, i._mtr && i._mtr.track("video_play"), t.removeEventListener("onStateChange", "stateChange"))
      }
     }
    })
   }), "undefined" == typeof i.onYouTubeIframeAPIReady && (i.onYouTubeIframeAPIReady = function() {
    i.YTReady.forEach(function(e, t) {
     i.YTReady[t]()
    })
   })
  },
  onVimeoAPIReady: function(e) {
   var t = this;
   this.vimeoInterval = setInterval(function() {
    return "undefined" == typeof $f ? !1 : (clearInterval(t.vimeoInterval), void e())
   }, 10)
  }
 }, e.fn.monovideo = function() {
  return this.each(function() {
   e.data(this, "monovideo") || e.data(this, "monovideo", new n(this))
  })
 }
}(jQuery, modernizr, window), $('.video[data-req="video"]').each(function(e, t) {
 $(t).monovideo()
});