"use strict";
import modernizr from 'modernizr';

!function (i, t, e) {
	var s = function (t, s) {
		var n = {
			auto: !1,
			arrows: !1,
			dots: !1,
			interval: 5e3,
			duration: 600,
			pause: !1,
			transition: "sliding",
			"transition-md": "sliding",
			"transition-sm": "sliding",
			viewport: "lg",
			imagenr: !1,
			"imagenr-md": !1,
			"imagenr-sm": !1
		};
		this.$element = i(t), this.$slidesContainer = this.$element.find(".slides"), this.$window = i(e), this.liNr = this.$element.find(".slide ul li").length, this.$slides = !1, this.isRunning = void 0, this.slideCount = null, this.currentSlide = 0, this.isInTool = document.body.hasAttribute("data-istool"), this.options = i.extend({}, n, s), this.lazyTimeout = !1, this.init()
	};
	s.prototype = {
		init: function () {
			var i = this;
			this.hasCaption = this.$element.data("settings").indexOf("captionwiththumbnail=true") > -1 && e.navigator.userAgent.indexOf("MSIE") > 0, this.setPortraits(), this.setViewport(), this.createSlides(1), this.$element.on("lazyloadFinished", function () {
				i.setElementHeight(), i.setPortraits(), clearTimeout(i.lazyTimeout), i.lazyTimeout = setTimeout(function () {
					i.addZoom()
				}, 500)
			}), this.options.shopThumbs && this.createShopThumbs(), this.hasCaption && (this.$slidesContainer.css("display", "inline-block").find(".slide").css("display", "inline-block"), this.captionOverflow()), this.$window.on("load", function () {
				i.setElementHeight(), e.location.hash.length > 0 && (location.hash = "#" + e.location.hash)
			}), this.$window.on("resize", function () {
				var t = i.viewport;
				i.setViewport(), i.viewport !== t && i.createSlides(), i.hasCaption && setTimeout(function () {
					this.captionOverflow()
				}, 50), i.setElementHeight()
			}), this.setElementHeight(function () {
				"PhantomJS/monoTool" !== e.navigator.userAgent && (i.$slidesContainer.find(".slide").hide(), i.$slidesContainer.find(".slide").eq(0).show())
			})
		},
		setPortraits: function () {
			this.$element.find("img").each(function (t, e) {
				var s = new Image;
				s.onload = function () {
					this.height > this.width && i(e).addClass("portrait")
				}, s.src = i(e).attr("src")
			})
		},
		setViewport: function () {
			this.$window.width() < 768 ? this.viewport = "sm" : this.$window.width() < 1200 ? this.viewport = "md" : this.viewport = "lg"
		},
		createSlides: function (t) {
			var s = this.options.imagenr ? parseInt(this.options.imagenr) : 99999;
			"sm" == this.viewport ? this.options["imagenr-sm"] ? s = parseInt(this.options["imagenr-sm"]) : this.options["imagenr-md"] && (s = parseInt(this.options["imagenr-md"])) : "md" == this.viewport && this.options["imagenr-md"] && (s = parseInt(this.options["imagenr-md"])), s && this.$slidesContainer.find(".slide").length > 1 && (this.$slidesContainer.find(".slide:not([data-firstslide]) li").appendTo(this.$slidesContainer.find(".slide[data-firstslide] ul.pics")), this.$slidesContainer.find(".slide:not([data-firstslide])").remove()), t && (this.$slidesContainer.find(".slide").removeAttr("data-firstslide"), this.$slidesContainer.find(".slide").attr("data-firstslide", !0)), this.$element.find(".dots, .arrowAfter, .arrowBefore").remove();
			for (var n = 0; n < parseInt(this.liNr / s); n++) {
				var a = this.$element.find(".slide[data-firstslide] ul li");
				if (a.length > s)
					for (var o = i('<div class="slide"><ul class="pics clear"></ul></div>').appendTo(this.$slidesContainer), r = 0; s > r; r++) a[s + r] && i(a[s + r]).appendTo(o.find("ul.pics"))
			}
			if (this.$slides = this.$slidesContainer.find(".slide"), this.slideCount = this.$slides.length, this.slideCount > 1 && (this.initSlider(), this.isInTool && e.parent.iframeGrids && e.parent.iframeGrids.remakeGridsStyle()), this.$element.data("slidesCreated", !0), this.addZoom(), void 0 !== i("body").attr("data-enablefocusindication")) {
				var d = this.$slides.find("li a");
				d.attr("tabindex", "").css("outline-style", "solid");
				var l = d.css("outlineWidth");
				d.removeAttr("tabindex").css({
					"outline-style": ""
				}), d.each(function (i, t) {
					t.style.setProperty("--outlinewidth", l)
				})
			}
		},
		createShopThumbs: function () {
			var t = this;
			this.$shopThumbsList = this.$element.find(".shopThumbsList").length ? this.$element.find(".shopThumbsList") : i('<ul class="shopThumbsList clear"></ul>').insertAfter(this.$slidesContainer), this.$shopThumbsList.empty(), this.$slides.each(function (e, s) {
				var n = i(s).find("li").clone();
				n.find("a").contents().unwrap(), n.find("> *:not(.thumb)").remove(), t.$shopThumbsList.append(n)
			}), this.bindShopThumbsEvent()
		},
		bindShopThumbsEvent: function () {
			var t = this;
			this.$shopThumbsList.on("click", "li", function () {
				var e = t.$shopThumbsList.find("li").index(i(this));
				t.changeSlide(e)
			}), this.addZoom()
		},
		addZoom: function () {
			return 0 === i("html[data-req]").length || document.documentElement.clientWidth < 1200 ? !1 : void(i("html").attr("data-req").indexOf("zoom") > -1 && e._monoLoader.utils.waitFor(function () {
				return "function" == typeof e.$.fn.mlens
			}, function () {
				i(".slides >.slide img").each(function (t, e) {
					i(e).mlens({
						imgSrc: i(e).attr("src"),
						zoomLevel: 1.4,
						borderSize: 1,
						lensSize: ["30%", "30%"]
					})
				})
			}))
		},
		initSlider: function () {
			var e = this;
			return this.options.dots && this.createDots(), this.options.arrows && this.createArrows(), this.isInTool ? !1 : (e.slideCount > 0 && e.start(), !t.touchevents && e.options.pause && e.slideCount > 0 && e.$slidesContainer.on({
				mouseenter: function () {
					e.stop()
				},
				mouseleave: function () {
					e.start()
				}
			}), e.$slides.each(function (t, e) {
				i(e).attr("aria-hidden", "true").attr("tabindex", "-1")
			}), e.$slidesContainer.on("swipe", function (i) {
				var t = "left" == i.direction ? -i.delta.x : i.delta.x;
				e.$slidesContainer.find(".slide:first").css("margin-left", t + "px")
			}), e.$slidesContainer.on("quickswipeleft", function () {
				e.$slidesContainer.find(".slide:first").css("margin-left", ""), e.start(), e.nextSlide(1, !1)
			}), e.$slidesContainer.on("quickswiperight", function () {
				e.$slidesContainer.find(".slide:first").css("margin-left", ""), e.start(), e.previousSlide(1, !1)
			}), e.$slidesContainer.on("swipeleft", function (i) {
				e.$slidesContainer.find(".slide:first").css("margin-left", ""), e.start(), i.delta.x > e.$slidesContainer.width() / 2 && e.nextSlide(1, !1)
			}), void e.$slidesContainer.on("swiperight", function (i) {
				e.$slidesContainer.find(".slide:first").css("margin-left", ""), e.start(), i.delta.x > e.$slidesContainer.width() / 2 && e.previousSlide(1, !1)
			}))
		},
		start: function () {
			var i = this;
			i.isRunning && clearInterval(i.isRunning), i.options.auto && (i.isRunning = setInterval(function () {
				i.nextSlide()
			}, i.options.interval))
		},
		stop: function () {
			clearInterval(this.isRunning), this.isRunning = void 0
		},
		setElementHeight: function (t) {
			var e = this,
				s = "lg" == this.viewport ? "transition" : "transition-" + this.viewport,
				n = this.$element.find(".slide.normalizeSize"),
				a = this.$element.find("> .slides");
			n.removeClass("normalizeSize"), a.css("height", ""), this.isInTool || "sliding" == this.options[s] ? a.find(".slide").css({
				opacity: "",
				display: ""
			}) : _monoLoader.utils.waitFor(function () {
				var t = i("body").find(".gallery,.imagelist,.catalog,.shopfilter").not(".hidden-" + e.viewport);
				if (t.length) {
					var s = t.length,
						n = 0;
					return t.each(function (t, e) {
						i(e).is(":visible") && n++
					}), s == n && "true" === i('link[href$="gallery.css"]').attr("data-loaded")
				}
				return !0
			}, function () {
				a.css("height", e.$element.height() + "px"), n.addClass("normalizeSize"), "function" == typeof t && t()
			})
		},
		destroy: function () {
			this.$element.find(".dots, .arrowAfter, .arrowBefore").remove(), this.$element.data("monogallery", null), this.$slidesContainer.find(".slide:not([data-firstslide]) li").appendTo(this.$slidesContainer.find(".slide[data-firstslide] ul.pics")), this.$slidesContainer.find(".slide:not([data-firstslide])").remove(), this.$slidesContainer.find(".slide[data-firstslide]").removeAttr("data-firstslide")
		},
		nextSlide: function n(i, t) {
			var e = this;
			void 0 === t && (t = !0);
			var s = "lg" == this.viewport ? "transition" : "transition-" + this.viewport,
				a = e.options.hasOwnProperty(s) ? e.options[s] : "sliding",
				o = void 0;
			switch (a) {
			case "sliding":
				var r = i ? i : 1,
					d = 0;
				o = e.$slidesContainer.find(".slide").first(), t ? (e.$slidesContainer.css({
					dummy: ""
				}), e.$slidesContainer.animate({
					dummy: -100 * r
				}, {
					duration: e.options.duration,
					step: function (i) {
						var t = i + 100 * d; - 100 >= t ? (e.$slidesContainer.append(o.css("margin-left", "")), o = e.$slidesContainer.find(".slide").first(), d++) : o.css("margin-left", t + "%")
					},
					complete: function () {
						e.currentSlide = e.$slides.index(o), e.updateActiveDot(), e.updateSlideAria(), o.css("margin-left", "")
					}
				})) : (e.$slidesContainer.append(o.css("margin-left", "")), o = e.$slidesContainer.find(".slide").first(), e.currentSlide = e.$slides.index(o), e.updateActiveDot(), e.updateSlideAria(), o.css("margin-left", ""));
				break;
			case "slowFadeIn":
			case "fastFadeIn":
				var l = e.$slidesContainer.find(".slide"),
					h = "fastFadeIn" == a ? 400 : 1e3;
				o = e.$slidesContainer.find('.slide[data-active="true"]').length ? e.$slidesContainer.find('.slide[data-active="true"]') : l.first();
				var n = o.is(e.$slidesContainer.find(".slide:last")) ? e.$slidesContainer.find(".slide").first() : o.next();
				void 0 !== i && (n = o.nextAll(".slide").eq(i - 1)), e.fadeAnimation(o, n, h), e.currentSlide = e.$slides.index(n), e.updateActiveDot(), e.updateSlideAria()
			}
		},
		previousSlide: function (i, t) {
			var e = this;
			void 0 === t && (t = !0);
			var s = "lg" == this.viewport ? "transition" : "transition-" + this.viewport,
				n = e.options.hasOwnProperty(s) ? e.options[s] : "sliding",
				a = void 0;
			switch (n) {
			case "sliding":
				var o = i ? i : 1,
					r = 0;
				a = e.$slidesContainer.find(".slide").last(), t ? (e.$slidesContainer.prepend(a.css("margin-left", "-100%")), e.$slidesContainer.css({
					dummy: ""
				}), e.$slidesContainer.animate({
					dummy: 100 * o
				}, {
					duration: e.options.duration,
					step: function (i) {
						var t = i - 100 * r;
						t > 100 ? (e.$slidesContainer.prepend(e.$slidesContainer.find(".slide").last().css("margin-left", "-100%")), a.css("margin-left", ""), a = e.$slidesContainer.find(".slide").first(), r++) : a.css("margin-left", -(100 - t) + "%")
					},
					complete: function () {
						e.currentSlide = e.$slides.index(a), e.updateActiveDot(), e.updateSlideAria(), a.css("margin-left", "")
					}
				})) : (e.$slidesContainer.prepend(e.$slidesContainer.find(".slide").last().css("margin-left", "-100%")), a.css("margin-left", ""), a = e.$slidesContainer.find(".slide").first(), e.currentSlide = e.$slides.index(a), e.updateActiveDot(), e.updateSlideAria(), a.css("margin-left", ""));
				break;
			case "fastFadeIn":
			case "slowFadeIn":
				var d = e.$slidesContainer.find(".slide"),
					l = "fastFadeIn" == n ? 400 : 1e3;
				a = e.$slidesContainer.find('.slide[data-active="true"]').length ? e.$slidesContainer.find('.slide[data-active="true"]') : d.last();
				var h = a.is(e.$slidesContainer.find(".slide:first")) ? e.$slidesContainer.find(".slide").last() : a.prev();
				void 0 !== i && (h = a.prevAll(".slide").eq(i - 1)), e.fadeAnimation(a, h, l), e.currentSlide = e.$slides.index(h), e.updateActiveDot(), e.updateSlideAria()
			}
			e.updateSlideAria()
		},
		changeSlide: function (i) {
			i !== this.currentSlide && (i > this.currentSlide ? this.nextSlide(i - this.currentSlide) : this.previousSlide(this.currentSlide - i), this.addZoom())
		},
		fadeAnimation: function (t, e, s, n) {
			t.removeAttr("data-active").css({
				position: "absolute",
				top: 0,
				left: 0
			}).animate({
				opacity: 0
			}, {
				duration: s,
				queue: !1,
				complete: function () {
					i(this).addClass("normalizeSize").css({
						position: "",
						top: "",
						left: ""
					}), "function" == typeof n && n()
				}
			}), e.css({
				opacity: 0
			}).removeClass("normalizeSize").css({
				display: "block"
			}).animate({
				opacity: 1
			}, {
				duration: s,
				queue: !1
			}).attr("data-active", !0)
		},
		createDots: function () {
			var t = this;
			t.$slidesContainer.find(".dots").length && t.$slidesContainer.find(".dots").remove();
			var e = i('<ul class="dots"></ul>');
			t.$slides.each(function (s) {
				var n = i("<li></li>");
				e.append(n), 0 === s && e.find("li:eq(0)").addClass("active"), t.isInTool || n.on("click", function () {
					t.changeSlide(e.find("li").index(n))
				})
			}), t.$element.find(".slides").append(e)
		},
		updateActiveDot: function (i) {
			var t = i ? i : this.currentSlide;
			this.$slidesContainer.find(".dots li").removeClass("active"), this.$slidesContainer.find(".dots li:eq(" + t + ")").addClass("active")
		},
		updateSlideAria: function () {
			var t = this;
			t.$slides.each(function (e, s) {
				var n = "true",
					a = -1;
				e === t.currentSlide && (n = "false", a = 0), i(s).attr("aria-hidden", n).attr("tabindex", a)
			})
		},
		createArrows: function () {
			var t = this;
			if (!this.$slidesContainer.find(".arrowBefore").length) {
				var s = this.$element.data("beforeText") || "Before",
					n = this.$element.data("afterText") || "After",
					a = i('<a href="#" class="arrow arrowBefore" title="' + s + '" aria-label="' + s + '">&#xF104;</a>'),
					o = i('<a href="#" class="arrow arrowAfter" title="' + n + '" aria-label="' + n + '">&#xF105;</a>');
				this.$slidesContainer.prepend([a, o]), a.add(o).on("mousedown", function () {
					t.stop()
				}), a.on("click", function (i) {
					i.preventDefault(), t.start(), t.previousSlide(), e._mtr && e._mtr.track("gallery_prev")
				}), o.on("click", function (i) {
					i.preventDefault(), t.start(), t.nextSlide(), e._mtr && e._mtr.track("gallery_next")
				})
			}
		},
		captionOverflow: function () {
			this.$slidesContainer.find(".caption").each(function (t, e) {
				i(e).width(i(e).parent().width())
			})
		}
	}, i.fn.monogallery = function (t) {
		return this.each(function () {
			i.data(this, "monogallery") || i.data(this, "monogallery", new s(this, t))
		})
	}
}(jQuery, modernizr, window), $('[data-req*="gallery"]').each(function (i, t) {
	function e(i) {
		if (!i) return {};
		var t = {},
			e = i.split(",");
		return $(e).each(function (i, e) {
			var s = e.split("=");
			s[0] = s[0].replace(" ", ""), t[s[0]] = "true" == s[1] || "false" == s[1] ? "true" == s[1] : s[1]
		}), t
	}
	$(t).monogallery(e($(t).attr("data-settings")))
});