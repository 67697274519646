"use strict";
import modernizr from 'modernizr';

!function(t, e, i) {
 function n() {
  if (null === s) {
   var t = getComputedStyle(i.body);
   s = t.direction || "ltr"
  }
  return s
 }
 var s = null,
  o = function(e, i) {
   var n = {
    container: ".slide-container",
    slides: ".slide",
    previous: '[data-bind~="previous-slide"]',
    next: '[data-bind~="next-slide"]',
    index: void 0,
    speed: 300,
    auto: !1,
    interval: 5e3,
    pause: !0,
    keyboard: !1,
    onInit: void 0,
    onChange: void 0
   };
   this.options = t.extend({}, n, i), this.$element = t(e), this.$window = t(window), this.$container = this.$element.find(this.options.container), this.$slides = this.$element.find(this.options.slides), this.slideCount = this.$slides.length - 1, this.currentSlide = 0, this.currentOffset = 0, this.offsetMethod = void 0, this.transitionMethod = void 0, this.setOffset = void 0, this.setTransition = void 0, this.animating = void 0, this.$btnPrevious = this.$element.find(this.options.previous), this.$btnNext = this.$element.find(this.options.next), this.clickEvent = "click", this.slideCount > 0 && this.init()
  };
 o.prototype = {
  getOffsetDirection: function(t) {
   switch (n()) {
    case "rtl":
     return t;
    case "ltr":
    default:
     return -1 * t
   }
  },
  init: function() {
   var n = this;
   e.touchevents && (n.clickEvent = "click tap"), n.setOffsetMethod(), n.setTransitionMethod(), void 0 !== n.options.index && n.changeSlide(n.options.index, !1), "tap" == n.clickEvent && (n.$btnPrevious.on("click", function() {
    return !1
   }), n.$btnNext.on("click", function() {
    return !1
   })), n.$btnPrevious.on(n.clickEvent, function(t) {
    n.previousSlide(), t.stopPropagation(), t.preventDefault()
   }), n.$btnNext.on(n.clickEvent, function(t) {
    n.nextSlide(), t.stopPropagation(), t.preventDefault()
   }), n.$element.on("quickswiperight", function() {
    n.previousSlide(!1)
   }), n.$element.on("quickswipeleft", function() {
    n.nextSlide(!1)
   }), n.$element.on("swipe", function(t) {
    var e = "left" == t.direction ? n.currentOffset - t.delta.x : n.currentOffset + t.delta.x;
    e = n.getOffsetDirection(e), n.setOffset(n.$container, e + "px")
   }), n.$element.on("swipeleft", function(t) {
    t.delta.x > n.$slides.width() / 2 ? n.nextSlide(!1) : n.changeSlide(n.currentSlide)
   }), n.$element.on("swiperight", function(t) {
    t.delta.x > n.$slides.width() / 2 ? n.previousSlide(!1) : n.changeSlide(n.currentSlide)
   }), n.options.keyboard && t(i).on("keydown", function(t) {
    switch (t.keyCode) {
     case 37:
      n.previousSlide();
      break;
     case 39:
      n.nextSlide()
    }
   }), !e.touchevents && n.options.auto && n.options.pause && n.$element.on({
    mouseenter: t.proxy(n.stop, n),
    mouseleave: t.proxy(n.start, n)
   }), n.$element.addClass("slider-ready"), n.$window.on("load", function() {
    n.options.auto && n.start()
   }), n.$window.on("resize", function() {
    n.changeSlide(n.currentSlide, !1)
   }), n.options.onInit && n.options.onInit.call(n)
  },
  previousSlide: function(t) {
   void 0 === t && (t = !0);
   var e = 0 === this.currentSlide ? t ? this.slideCount : 0 : this.currentSlide - 1;
   this.options.auto && this.start(), this.changeSlide(e), this.$element.hasClass("lightbox") && window._mtr && window._mtr.track("gallery_overlay_prev")
  },
  nextSlide: function(t) {
   void 0 === t && (t = !0);
   var e = this.currentSlide === this.slideCount ? t ? 0 : this.slideCount : this.currentSlide + 1;
   this.options.auto && this.start(), this.changeSlide(e), this.$element.hasClass("lightbox") && window._mtr && window._mtr.track("gallery_overlay_next")
  },
  changeSlide: function(t, e) {
   void 0 === e && (e = !0);
   var i = this;
   i.currentSlide = t, i.currentOffset = i.getOffsetDirection(i.$slides.outerWidth(!0) * i.currentSlide), e ? (i.setTransition && i.setTransition(i.$container, i.options.speed), i.animating && clearTimeout(i.animating), i.animating = setTimeout(function() {
    i.animating = void 0, i.setTransition && i.setTransition(i.$container, 0), i.options.onChange && i.options.onChange.call(i)
   }, i.options.speed)) : i.options.onChange && i.options.onChange.call(i), i.setOffset(i.$container, i.currentOffset + "px")
  },
  start: function() {
   var t = this;
   t.isRunning && clearInterval(t.isRunning), t.options.auto && (t.isRunning = setInterval(function() {
    t.nextSlide()
   }, t.options.interval))
  },
  stop: function() {
   clearInterval(this.isRunning), this.isRunning = void 0
  },
  setOffsetMethod: function() {
   var t = this;
   t.setOffset = function() {
    return t.offsetMethod = "left", e.csstransforms3d ? (t.offsetMethod = e.prefixed("transform"), function(e, i) {
     e[0].style[t.offsetMethod] = "translate3d(" + i + ", 0px, 0px)"
    }) : e.csstransforms ? (t.offsetMethod = e.prefixed("transform"), function(e, i) {
     e[0].style[t.offsetMethod] = "translate(" + i + ", 0px)"
    }) : function(e, i) {
     e[0].style[t.offsetMethod] = i
    }
   }()
  },
  setTransitionMethod: function() {
   var t = this;
   t.setTransition = function() {
    return e.csstransitions ? (t.transitionMethod = e.prefixed("transition"), function(e, i) {
     e[0].style[t.transitionMethod] = "all " + i + "ms ease-in-out"
    }) : !1
   }()
  }
 }, t.fn.imgSlider = function(e) {
  return this.each(function() {
   t.data(this, "slider") || t.data(this, "slider", new o(this, e))
  })
 }, t(function() {
  var e = t('[data-bind~="slider"]'),
   i = t('[data-bind~="carousel"]');
  e.length && e.imgSlider({
   onChange: function() {
    this.$element.find(".page-indication li").removeClass("active").eq(this.currentSlide).addClass("active")
   }
  }), i.each(function() {
   var e = t(this);
   e.imgSlider({
    auto: !0,
    interval: ~~e.data("sliderInterval"),
    pause: !!e.data("sliderPause"),
    onChange: function() {
     this.$element.find(".carousel-indicators li").removeClass("active").eq(this.currentSlide).addClass("active")
    }
   })
  })
 })
}(jQuery, modernizr, document);