"use strict";

var _monoAnchor = {
 anchors: $("a[href*=\\#]:not([href^=\\http])")[3],
 $header: $("header"),
 $window: $(window),
 $nav: $(".module.nav"),
 init: function() {
  var t = this;
  this.isInTool = document.body.hasAttribute("data-istool"), this.headerSettings = this.makeOptionsObj(this.$header.attr("data-settings")), $(window).on("hashchange", function() {
   if (window.location.hash) {
    var e = t.filterVisibleAnchors($('a[name="' + window.location.hash.replace("#", "") + '"]'));
    e && t.scrollTo(e)
   }
  }), $(this.anchors).each(function(e, i) {
   $(i).on("click.anchor", $.proxy(t.anchorHandler, t))
  }), window.location.hash && this.scrollOnLoad()
 },
 anchorHandler: function(t) {
  var e = this,
   i = $(t.currentTarget),
   n = this.filterVisibleAnchors($(i[0].hash).length ? $(i[0].hash) : $('a[name="' + i[0].hash.replace("#", "") + '"]'));
  if (n.length) {
   t.preventDefault(), this.$nav.hasClass("opened-menu") && this.$nav.find(".closeBtn").trigger("click");
   var a = setInterval(function() {
    $("body").hasClass("noScroll") || (clearInterval(a), e.scrollTo(n))
   }, 10)
  }
 },
 scrollTo: function(t) {
  var e = this.isInTool ? $("html,body").add(this.$window[0].parent.iframeGrids.trDiv) : $("html,body"),
  i = 0;
  i = t.offset().top - parseInt(t.parent().css("padding-top")) > $(document).outerHeight(1) - this.$window.height() ? $(document).height() - this.$window.height() : t.offset().top - parseInt(t.parent().css("padding-top")), !this.isInTool && this.$header.attr("data-req") && -1 == this.headerSettings.indexOf("headerminify-" + this.checkVP()) && this.$header.attr("data-req").indexOf("headerfixed") > -1 && (!this.headerSettings.length || this.headerSettings.indexOf("headerfixed") > -1 || this.headerSettings.indexOf("headerfixed-" + this.checkVP()) > -1) && (i -= this.$header.outerHeight()), e.animate({
   scrollTop: i
  }, 500, "swing"), window.location.hash && window.location.hash == "#" + t.attr("name") || window.history.pushState(null, null, "#" + t.attr("name"))
 },
 scrollOnLoad: function() {
  var t = this,
   e = window.location.hash,
   i = this.filterVisibleAnchors($(e).length && !$(e)[0].classList.length && $(e).is("a") ? $(e) : $("a[name=" + e.replace("#", "") + "]"));
  i.length && $(".module.gallery").filter(":animated").promise().done(function() {
   setTimeout(function() {
    t.scrollTo(i)
   }, 250)
  })
 },
 filterVisibleAnchors: function(t) {
  var e = t.text("a").filter(":visible");
  return t.text(""), e
 },
 checkVP: function() {
  return $(window).width() < 768 ? "sm" : $(window).width() > 768 && $(window).width() < 1199 ? "md" : "lg"
 },
 makeOptionsObj: function(t) {
  return t ? t.split(",") : []
 }
};
_monoAnchor.init();