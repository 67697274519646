"use strict";
import modernizr from 'modernizr';


! function(t, i, e) {
 var n = 0,
  o = function(i) {
   this.$window = t(e), this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent), this.$element = t(i), this.bgFixed = "fixed" == this.$element.css("backgroundAttachment") && "fixed" !== this.$element[0].style.backgroundAttachment, this.isInTool = document.body.hasAttribute("data-istool"), this.backgroundPositionX = this.$element.css("background-position").split(" ")[0], this.instanceCounter = n, this.init()
  };
 o.prototype = {
  init: function() {
   this.isMobile || (this.bgFixed || this.$element.css("backgroundAttachment", "fixed"), this.$element.css("background-position", this.backgroundPositionX + " " + this.getScrollAmount() + "px"), this.$window.on("scroll.parallax-" + this.instanceCounter, t.proxy(this.scrollHandler, this)))
  },
  scrollHandler: function() {
   this.isInTool && this.bgFixed != ("fixed" == this.$element.css("backgroundAttachment") && "fixed" !== this.$element[0].style.backgroundAttachment) && (this.$element.data("monoparallax").destroy(), this.$element.monoparallax()), this.$element.css({
    backgroundPosition: this.backgroundPositionX + " " + this.getScrollAmount() + "px"
   })
  },
  getScrollAmount: function() {
   var t = this.bgFixed ? this.$window.scrollTop() : this.$window.scrollTop() - this.$element.offset().top;
   return -(t / 2)
  },
  destroy: function() {
   "fixed" == this.$element[0].style.backgroundAttachment && (this.$element[0].style.backgroundAttachment = ""), this.$element[0].style.backgroundPositionY = "", this.$window.off("scroll.parallax-" + this.instanceCounter), this.$element.removeData("monoparallax")
  }
 }, t.fn.monoparallax = function() {
  return this.each(function() {
   t.data(this, "monoparallax") || (t.data(this, "monoparallax", new o(this)), n++)
  })
 }
}(jQuery, modernizr, window), $('[data-req*="parallax"]').each(function(t, i) {
 $(i).monoparallax()
});