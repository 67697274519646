// "use strict";
// $(window).scroll(function() {
//   if ($(this).scrollTop()) {
//       $('#scrol').fadeIn();
//   } else {
//       $('#scrol').fadeOut();
//   }
// });

// $("#scrol").click(function() {
//   $("html, body").animate({scrollTop: 0}, 1000);
// });

"use strict";
var _scrollTop = {
 $window: $(window),
 $header: $("header"),
 $scrollIcon: $(".scrollIcon"),
 isInTool: document.body.hasAttribute("data-istool"),
 init: function() {
  this.showIcon(), this.bindScrollEvent(), this.bindScrollTop()
 },
 bindScrollEvent: function() {
  var o = this;
  o.isInTool ? o.$scrollIcon.removeClass("hidden") : this.$window.on("scroll.scrollTop", function() {
   o.showIcon()
  })
 },
 showIcon: function() {
  var o = this,
   l = o.$window.scrollTop();
  l > o.$header.height() ? o.$scrollIcon.removeClass("hidden").addClass("visible") : o.$scrollIcon.removeClass("visible").addClass("hidden")
 },
 bindScrollTop: function() {
  var o = this;
  o.$scrollIcon.click(function(o) {
   o.preventDefault(), $("body, html").animate({
    scrollTop: 0
   }, 500)
  })
 }
};
_scrollTop.init();