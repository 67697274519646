"use strict";
import modernizr from 'modernizr';

var _monoNav = {
  $nav: $(".module.nav"),
  $window: $(window),
  $body: $("body"),
  $skipNavigation: $(".skipNavigation"),
  $wrapper: $("body > div:first"),
  clickEvent: "click.mononav",
  windowWidth: $(window).width(),
  options: {
   push: !1,
   direction: "left"
  },
  noScrollClass: window.navigator.userAgent.match(/(iPad|iPhone|iPod|Safari)/g) && !window.navigator.userAgent.match(/Chrome/g) ? "safariNoScroll" : "noScroll",
  tabbableElements: $("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable], .nav li"),
  init: function() {
   this.hasNav = this.$nav.length, modernizr.touchevents && (this.clickEvent = window.navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? this.clickEvent + " touchstart.mononav" : this.clickEvent + " tap.mononav"), this.hasNav && (this.setOptions(this.$nav.attr("data-settings")), this.setViewport(), this.buildNav(), this.openActiveParents(), this.bindDropdownEvent(), this.bindOpenEvent(), this.bindCloseEvent(), this.bindResizeEvent(), this.bindKeyboardEvent(this.$nav.attr("data-keyboard")), this.doubleTapToGo(this.$nav.find("li:has(ul)")), this.addSkipClickEvent())
  },
  buildNav: function() {
   var t = this;
   this.$navList = this.$nav.find("> ul"), this.$listItems = this.$navList.find("li"), $('<li class="closeBtn" title="Close Menu" aria-label="Close Menu" aria-hidden="true" role="button" tabindex="-1">').prependTo(this.$navList).on(this.clickEvent, $.proxy(this.closeMenu, this)), this.$listItems.each(function(i, a) {
    var n = $(a).find("a:first").text() + " links";
    if ($(a).find("> ul").length) {
     var e = "navUl_" + i;
     $(a).addClass("hasChildren").attr("role", "link").append('<a title="' + n + '" aria-label="' + n + '" class="dropIco"/>');
     var s = $(a).find("> ul");
     s.attr("aria-labelledby", e), s.attr("id", e), t.isBurgerMenu() ? (t.$nav.attr("role", "button"), t.$nav.attr("tabindex", "0"), t.$nav.find("navContainer").attr("role", "presentation")) : (s.attr("role", "menu"), s.find("li").find("a").attr("role", "menuitem"))
    }
    $(a).parent().hasClass("navContainer") ? ($(a).attr("tabindex", 0), $(a).find("a").attr("tabindex", -1), $(a).find("a.dropIco").attr("tabindex", 0), $(a).find("a").attr("aria-label", n), $(a).find("li").find("a").attr("role", "menuitem")) : $(a).attr("tabindex", -1), t.isBurgerMenu() && $(a).attr("aria-hidden", "true").attr("tabindex", "-1"), t.$window.on("resize.mononav", function() {
     t.isBurgerMenu() && $(a).attr("aria-hidden", "true").attr("tabindex", "-1")
    }), $(a).hasClass("active") && $(a).attr("aria-current", "page")
   })
  },
  openActiveParents: function() {
   this.$listItems.filter(".active").parents(".hasChildren").addClass("showChildren"), this.setVisibleListsTabbable()
  },
  bindDropdownEvent: function() {
   this.$listItems.filter(".hasChildren").find(".dropIco").on(this.clickEvent, function(t) {
    t.preventDefault(), t.stopImmediatePropagation(), $(this).parent().toggleClass("showChildren");
    var i = $(this).parent().hasClass("showChildren");
    $(this).parent().attr("aria-expanded", i)
   })
  },
  bindOpenEvent: function() {
   var t = this;
   this.$nav.on(this.clickEvent, function(i) {
    i.target != this || t.$nav.hasClass("opened-menu") || (i.stopImmediatePropagation(), i.preventDefault(), t.openMenu(i), $(this).attr("aria-expanded", "true"))
   })
  },
  bindKeyboardEvent: function(t) {
   var i = this,
    a = this;
   if (t) {
    var n = Object.freeze({
     TAB: 9,
     ENTER: 13,
     ESC: 27,
     SPACE: 32,
     LEFT: 37,
     UP: 38,
     RIGHT: 39,
     DOWN: 40,
     HOME: 36,
     END: 35
    });
    this.$nav.on("keydown", function(t) {
     switch (t.which) {
      case n.ENTER:
      case n.DOWN:
      case n.UP:
      case n.SPACE:
       t.stopImmediatePropagation(), t.preventDefault()
     }
    }), this.$nav.on("keyup", function(t) {
     var e = $(t.target);
     switch (t.which) {
      case n.ENTER:
      case n.SPACE:
       if (t.stopImmediatePropagation(), t.preventDefault(), e.is(".dropIco")) e.parent(".hasChildren").siblings(".hasChildren").removeClass("showChildren"), e.parent().toggleClass("showChildren"), e.parent().hasClass("showChildren") ? (i.toggleTabbingOnPage(!1), e.parent().find("> ul > li").attr("aria-hidden", !1).first().focus()) : (e.parent().find("> ul > li").attr("aria-hidden", !0), i.toggleTabbingOnPage(!0));
       else if (e.is(".closeBtn")) i.closeMenu(t);
       else if (e.is(".module.nav")) i.openMenu(t);
       else {
        var s = e.find("a").attr("href");
        s && (window.location = s)
       }
       break;
      case n.ESC:
       i.closeMenu(t), e = e.closest(".hover"), e.removeClass("hover"), i.toggleAttribute(e.find("ul")[0], "aria-expanded"), i.applyNavFocus(a.$nav) ? e.closest("li").focus() : i.isBurgerMenu() || e.closest("li").focus(), i.$nav.find("li.hasChildren").removeClass("showChildren"), i.toggleTabbingOnPage(!0);
       break;
      case n.DOWN:
       i.applyNavFocus(a.$nav) ? e.next().focus() : i.isBurgerMenu() || e.next().focus();
       break;
      case n.UP:
       i.applyNavFocus(a.$nav) ? e.prev().focus() : i.isBurgerMenu() || e.prev().focus();
       break;
      case n.HOME:
       i.applyNavFocus(a.$nav) ? e.parent().find("li:first-child").focus() : i.isBurgerMenu() || e.parent().find("li:first-child").focus();
       break;
      case n.END:
       i.applyNavFocus(a.$nav) ? e.siblings(":not(.closeBtn)").last().focus() : i.isBurgerMenu() || e.siblings(":not(.closeBtn)").last().focus();
       break;
      case n.TAB:
     }
     i.setVisibleListsTabbable()
    }), this.$listItems.on("keyup", function(t) {
     var a = $(t.target);
     switch (t.which) {
      case n.RIGHT:
       t.stopPropagation(), t.preventDefault(), a.hasClass("hasChildren") ? (i.openMenu(), a.toggleClass("hover"), i.toggleAttribute(a.find("ul")[0], "aria-expanded"), $(a.find("ul")[0]).find("li:first-child").focus()) : a.next().focus();
       break;
      case n.LEFT:
       t.stopPropagation(), t.preventDefault(), "menuitem" === a.attr("role") ? (i.closeMenu(t), a = a.closest(".hover"), a.removeClass("hover"), i.toggleAttribute(a.find("ul")[0], "aria-expanded"), a.closest("li").focus()) : a.prev().focus()
     }
    })
   }
  },
  applyNavFocus: function(t) {
   return this.isBurgerMenu() && t.hasClass("opened-menu")
  },
  bindResizeEvent: function() {
   var t = this;
   this.$window.on("resize.mononav", function(i) {
    return t.windowWidth == t.$window.width() ? !1 : (t.$nav.hasClass("opened-menu") && t.closeMenu(i), void t.setViewport())
   })
  },
  bindCloseEvent: function() {
   var t = this;
   $(document).on(this.clickEvent, function(i) {
    t.$nav.hasClass("opened-menu") && t.closeMenu(i)
   }), this.$navList.on(this.clickEvent, function(t) {
    t.stopPropagation()
   })
  },
  openMenu: function() {
   if (!this.isBurgerMenu()) return !1;
   this.$nav.addClass("opened-menu"), this.$window.width() > 767 ? this.$body.css("overflow-x", "hidden") : this.$body.css("overflow", "hidden");
   var t;
   if (t = this.$window.width() > 1200 ? 80 : this.$window.width() > 767 && this.$window.width() < 1199 ? 50 : 0, "left" === this.options.direction ? this.$navList.css("left", "auto").animate({
     right: t + "%"
    }, 300) : this.$navList.css("right", "auto").animate({
     left: t + "%"
    }, 300), this.$navList.each(function() {
     $(this).find('[data-req~="anchor"]').attr("onClick", "$('.closeBtn').click();")
    }), this.options.push) {
    var i;
    if (i = this.$window.width() > 1200 ? 20 : this.$window.width() > 767 && this.$window.width() < 1199 ? 50 : 100, this.$window.width() < 1199 && this.$body.addClass(this.noScrollClass), "left" === this.options.direction ? this.$wrapper.animate({
      "margin-left": i + "%"
     }, 300) : this.$wrapper.animate({
      "margin-left": -i + "%"
     }, 300), window.hasOwnProperty("_monoFixedHeader")) {
     var a = $("header.headerFixed");
     if (a.length) {
      var n = this.$window.width() / 100 * i;
      "left" === this.options.direction ? a.animate({
       left: n + parseFloat(a.css("left")) + "px",
       right: -(n - parseFloat(a.css("right"))) + "px"
      }, 300) : a.animate({
       right: n + parseFloat(a.css("right")) + "px",
       left: -(n - parseFloat(a.css("left"))) + "px"
      }, 300)
     }
    }
   }
   this.toggleTabbingOnPage(!1), this.$nav.find(".closeBtn").attr("tabindex", "0"), this.$nav.find(".showChildren").length ? (this.$nav.find(".showChildren > ul > li").attr("aria-hidden", "false").attr("tabindex", "0"), this.$nav.find(".showChildren .dropIco").attr("tabindex", 0)) : this.$listItems.each(function(t, i) {
    $(i).parent().hasClass("navContainer") && ($(i).attr("aria-hidden", "false").attr("tabindex", "0"), $(i).find("> .dropIco").attr("tabindex", "0"))
   }), this.$nav.find(".dropIco").attr("tabindex", "0"), this.applyNavFocus(this.$nav) && this.$window.width() < 768 && this.$nav.find(".closeBtn").attr("tabindex", "0").focus()
  },
  closeMenu: function(t) {
   var i = this;
   t.stopPropagation(), t.stopImmediatePropagation();
   var a = this;
   if (this.$nav.hasClass("opened-menu")) {
    if (this.$navList.each(function() {
      $(this).find('[data-req~="anchor"]').attr("onClick", "$('.closeBtn').click();")
     }), this.$body.css("overflow", "").css("overflow-x", ""), "left" === this.options.direction ? this.$navList.css("left", "auto").animate({
      right: "100%"
     }, 300, "swing", function() {
      $(this).css({
       left: "",
       right: ""
      }), a.$body.removeClass(a.noScrollClass), a.$nav.removeClass("opened-menu")
     }) : this.$navList.css("right", "auto").animate({
      left: "100%"
     }, 300, "swing", function() {
      $(this).css({
       left: "",
       right: ""
      }), a.$body.removeClass(a.noScrollClass), a.$nav.removeClass("opened-menu")
     }), this.options.push) {
     this.$body.hasClass(this.noScrollClass) && this.$wrapper.css({
      position: "fixed"
     }), this.$wrapper.animate({
      "margin-left": ""
     }, 300, function() {
      i.$wrapper.css({
       position: ""
      })
     });
     var n = $("header.headerFixed");
     if (window.hasOwnProperty("_monoFixedHeader") && n.length) {
      var e = this.$window.width() > 767 ? this.$window.width() / 100 * 20 : this.$window.width() / 100 * 90,
       s = Math.abs(parseFloat(n.css("left"))),
       o = Math.abs(parseFloat(n.css("right")));
      "left" === this.options.direction ? n.animate({
       left: s - e + "px",
       right: e - o + "px"
      }, 300) : n.animate({
       left: e - s + "px",
       right: o - e + "px"
      }, 300)
     }
    }
    this.$nav.attr("aria-expanded", this.$nav.hasClass("opened-menu"))
   }
   this.toggleTabbingOnPage(!0), this.$nav.find(".closeBtn").attr("tabindex", "-1"), this.$listItems.each(function(t, a) {
    $(a).attr("aria-hidden", "true").attr("tabindex", "-1"), $(a).find("a").attr("tabindex", "-1"), i.$nav.focus()
   }), this.$nav.find(".dropIco").attr("tabindex", "-1")
  },
  isBurgerMenu: function() {
   return "sm" == this.viewport || this.$nav.is('[data-settings*="verticalNav-' + this.viewport + '=true"]') || this.$nav.is('[data-settings*="vertical="]') && !this.$nav.is('[data-settings*="vertical=false"]') ? !0 : !1
  },
  doubleTapToGo: function(t) {
   if (!("ontouchstart" in window || navigator.msMaxTouchPoints || navigator.userAgent.toLowerCase().match(/windows phone os 7/i))) return !1;
   var i = this;
   this.curItem = !1, $(t).on("click", function(t) {
    if (!i.isBurgerMenu()) {
     t.stopImmediatePropagation();
     var a = $(t.currentTarget);
     a[0] != i.curItem[0] && (t.preventDefault(), i.curItem = a)
    }
   }), $(document).on("click touchstart MSPointerDown", function(t) {
    for (var a = !0, n = $(t.target).parents(), e = 0; e < n.length; e++) n[e] == i.curItem[0] && (a = !1);
    a && (i.curItem = !1)
   })
  },
  setOptions: function(t) {
   if (!t) return {};
   var i = this,
    a = t.split(",");
   $(a).each(function(t, a) {
    var n = a.split("=");
    n[0] = n[0].replace(" ", ""), i.options[n[0]] = "true" == n[1] || "false" == n[1] ? "true" == n[1] : n[1]
   })
  },
  setViewport: function() {
   this.$window.width() < 768 ? this.viewport = "sm" : this.$window.width() < 1200 ? this.viewport = "md" : this.viewport = "lg"
  },
  addSkipClickEvent: function() {
   var t = this;
   this.$skipNavigation.click(function(t) {
    var i = $("#skipNavigationTarget");
    t.preventDefault(), $(i).attr("tabindex", -1).on("blur focusout", function() {
     $(i).removeAttr("tabindex")
    }).focus(), $([document.documentElement, document.body]).animate({
     scrollTop: i.offset().top
    }, 0)
   }), this.$skipNavigation.find("a").on("focus", function() {
    t.$skipNavigation.css("z-index", 5)
   }), this.$skipNavigation.find("a").on("blur", function() {
    t.$skipNavigation.css("z-index", -1)
   })
  },
  toggleAttribute: function(t, i) {
   var a = $(t).attr(i) || "false";
   if (a) {
    var n = "true" === a ? "false" : "true";
    $(t).attr(i, n)
   }
  },
  toggleTabbingOnPage: function(t) {
   var i = t ? "0" : "-1";
   this.$nav.find(".dropIco").attr("tabindex", i), $.each(this.tabbableElements, function(t, a) {
    $(a).attr("tabindex", i)
   }), t && this.$nav.find("> ul li").each(function(t, i) {
    $(i).parent().hasClass("navContainer") ? ($(i).attr("tabindex", 0), $(i).find("a").attr("tabindex", -1), $(i).find("a.dropIco").attr("tabindex", 0)) : $(i).attr("tabindex", -1), $(i).hasClass("active") && $(i).attr("aria-current", "page")
   }), this.setVisibleListsTabbable()
  },
  setVisibleListsTabbable: function() {
   this.$nav.find("li:visible").attr("tabindex", 0), this.$nav.find("li:visible > .dropIco").attr("tabindex", 0), this.$nav.find("li[aria-hidden=true]").attr("tabindex", -1), this.$nav.find("li[aria-hidden=true] > .dropIco").attr("tabindex", -1)
  }
 };
 _monoNav.init();