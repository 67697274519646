! function(window, undefined) {
  function fixedEncodeURIComponent(str) {
   return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return "%" + c.charCodeAt(0).toString(16)
   })
  }

  function init(settings) {
   var i, opts = settings || {};
   if (opts.onError !== !1 && Canadarm.setUpOnErrorHandler(), opts.wrapEvents !== !1 && Canadarm.setUpEventListening(), opts.appenders)
    for (i = 0; i < opts.appenders.length; i++) Canadarm.addAppender(opts.appenders[i]);
   if (opts.handlers)
    for (i = 0; i < opts.handlers.length; i++) Canadarm.addHandler(opts.handlers[i]);
   opts.logLevel && (Canadarm.loggingLevel = opts.logLevel)
  }

  function addAppender(logAppender) {
   errorAppenders.push(logAppender)
  }

  function addHandler(logHandler) {
   errorHandlers.push(logHandler)
  }

  function gatherErrors(level, exception, message, data, appenders) {
   var errorAppender, attributes, i, key, logAttributes = {},
    localAppenders = appenders || errorAppenders;
   for (i = 0; i < localAppenders.length; i++) {
    errorAppender = localAppenders[i], attributes = errorAppender(level, exception, message, data);
    for (key in attributes) attributes.hasOwnProperty(key) && (logAttributes[key] = attributes[key])
   }
   return logAttributes
  }

  function pushErrors(logAttributes, handlers) {
   var errorHandler, i, localHandlers = handlers || errorHandlers;
   for (i = 0; i < localHandlers.length; i++)(errorHandler = localHandlers[i])(logAttributes)
  }

  function customLogEvent(level) {
   return function(message, exception, data, settings) {
    if (!(Canadarm.levelOrder.indexOf(level) < Canadarm.levelOrder.indexOf(Canadarm.loggingLevel))) {
     var options = settings || {},
      attrs = gatherErrors(level, exception, message, data, options.appenders);
     pushErrors(attrs, options.handlers)
    }
   }
  }

  function standardLogAppender(level, exception, message, data) {
   function findStackData(stack) {
    if (stack === undefined || null === stack) return {
     url: Canadarm.constant.UNKNOWN_LOG,
     lineNumber: Canadarm.constant.UNKNOWN_LOG,
     columnNumber: Canadarm.constant.UNKNOWN_LOG
    };
    for (var newStack, stackBits = stack.replace(/(\r\n|\n|\r)/gm, "").match(STACK_SCRIPT_COLUMN_LINE_FINDER), stackData = [], stackHasBits = null !== stackBits && stackBits !== undefined; stackHasBits;) stackBits = stackBits[1].match(STACK_SCRIPT_COLUMN_LINE_FINDER), newStack = null !== stackBits ? stackBits[1] : null, stackData = null !== stackBits ? stackBits : stackData, stackHasBits = null !== stackBits && stackBits !== undefined;
    return {
     url: stackData.length >= 1 ? stackData[1] : Canadarm.constant.UNKNOWN_LOG,
     lineNumber: stackData.length >= 1 ? stackData[2] : Canadarm.constant.UNKNOWN_LOG,
     columnNumber: stackData.length >= 1 ? stackData[3] : Canadarm.constant.UNKNOWN_LOG
    }
   }

   function pad(number) {
    return 10 > number ? "0" + number : number
   }
   var logAttributes, dataKey, stackData, dateTime, stack = exception ? exception.stack : (new Error).stack || null,
    scriptURL = Canadarm.constant.UNKNOWN_LOG,
    errorMessage = message || exception.message || Canadarm.constant.UNKNOWN_LOG,
    currentDate = new Date,
    pageURI = window.location.href,
    lineNumber = Canadarm.constant.UNKNOWN_LOG,
    columnNumber = Canadarm.constant.UNKNOWN_LOG,
    language = window.navigator.language || Canadarm.constant.UNKNOWN_LOG,
    characterSet = window.document.characterSet || window.document.charset || window.document.defaultCharset || Canadarm.constant.UNKNOWN_LOG,
    STACK_SCRIPT_COLUMN_LINE_FINDER = /(http\:\/\/.*\/.*\.js)\:(\d+)\:(\d+)(.*)$/;
   if (dateTime = currentDate.toISOString ? currentDate.toISOString() : function() {
     return currentDate.getUTCFullYear() + "-" + pad(currentDate.getUTCMonth() + 1) + "-" + pad(currentDate.getUTCDate()) + "T" + pad(currentDate.getUTCHours()) + ":" + pad(currentDate.getUTCMinutes()) + ":" + pad(currentDate.getUTCSeconds()) + "." + (currentDate.getUTCMilliseconds() / 1e3).toFixed(3).slice(2, 5) + "Z"
    }(), exception === undefined || null === exception || null === exception.stack)
    if ("function" == typeof window.document.getElementsByTagName) {
     var scripts = window.document.getElementsByTagName("script");
     scriptURL = (window.document.currentScript || scripts[scripts.length - 1]).src
    } else scriptURL = Canadarm.constant.UNKNOWN_LOG;
   else stackData = findStackData(stack), scriptURL = stackData.url, lineNumber = stackData.lineNumber, columnNumber = stackData.columnNumber;
   logAttributes = {
    characterSet: characterSet,
    columnNumber: columnNumber,
    language: language,
    lineNumber: lineNumber,
    logDate: dateTime,
    msg: "[" + level + "]: " + errorMessage,
    pageURL: pageURI,
    stack: stack || Canadarm.constant.UNKNOWN_LOG,
    type: "jserror",
    scriptURL: scriptURL
   };
   for (dataKey in data) data.hasOwnProperty(dataKey) && null !== data[dataKey] && data[dataKey] !== undefined && (logAttributes[dataKey] = data[dataKey]);
   return logAttributes
  }

  function beaconLogHandler(beaconURL) {
   return function(logAttributes) {
    var key, logValues = [],
     imageLogger = new Image;
    for (key in logAttributes) logAttributes.hasOwnProperty(key) && logValues.push(key + "=" + Canadarm.utils.fixedEncodeURIComponent(logAttributes[key] + "\n"));
    imageLogger.src = beaconURL + "?" + logValues.join("&")
   }
  }

  function consoleLogHandler(logAttributes) {
   var key, logValues = "";
   if (console)
    if (window.attachEvent) {
     for (key in logAttributes) logAttributes.hasOwnProperty(key) && (logValues += key + "=" + logAttributes[key] + "\n");
     console.error(logValues)
    } else "undefined" != typeof logAttributes.msg ? console.error(logAttributes.msg, logAttributes) : console.error(logAttributes)
  }

  function _watchFn(fn, context, settings) {
   if (fn && "function" == typeof fn || (fn = function() {}), fn && fn._wrapper) return fn._wrapper;
   var wrapper = function() {
    try {
     return fn.apply(context || this, arguments)
    } catch (error) {
     Canadarm.error(error.message, error, undefined, settings)
    }
   };
   return fn._wrapper = wrapper, wrapper
  }

  function _onError(errorMessage, url, lineNumber, columnNumber, exception) {
   var onErrorReturn;
   return _oldOnError && "function" == typeof _oldOnError && (onErrorReturn = _oldOnError.apply(this, arguments)), Canadarm.fatal(errorMessage, exception, {
    url: url,
    lineNumber: lineNumber,
    columnNumber: columnNumber
   }), onErrorReturn
  }

  function setUpOnErrorHandler() {
   window.onerror = _onError
  }

  function setUpEventListening() {
   var addEventListener, removeEventListener, eventListeners = [];
   window.EventTarget ? (addEventListener = window.EventTarget.prototype.addEventListener, removeEventListener = window.EventTarget.prototype.removeEventListener, window.EventTarget.prototype.addEventListener = function(event, callback, bubble) {
    return addEventListener.call(this, event, Canadarm.watch(callback), bubble)
   }, window.EventTarget.prototype.removeEventListener = function(event, callback, bubble) {
    return removeEventListener.call(this, event, Canadarm.watch(callback), bubble)
   }) : window.Element && window.Element.prototype && window.Element.prototype.attachEvent && window.Element.prototype.addEventListener === undefined && (Event.prototype.preventDefault || (Event.prototype.preventDefault = function() {
    this.returnValue = !1
   }), Event.prototype.stopPropagation || (Event.prototype.stopPropagation = function() {
    this.cancelBubble = !0
   }), Element.prototype.addEventListener || (addEventListener = function(type, listener) {
    var self = this,
     wrapper = function(e) {
      e.target = e.srcElement, e.currentTarget = self, listener.handleEvent ? listener.handleEvent(e) : listener.call(self, e)
     };
    "DOMContentLoaded" !== type && (this.attachEvent("on" + type, Canadarm.watch(wrapper)), eventListeners.push({
     object: this,
     type: type,
     listener: listener,
     wrapper: wrapper
    }))
   }, removeEventListener = function(type, listener) {
    for (var eventListener, counter = 0; counter < eventListeners.length;) {
     if (eventListener = eventListeners[counter], eventListener.object == this && eventListener.type === type && eventListener.listener == listener) {
      "DOMContentLoaded" !== type && this.detachEvent("on" + type, Canadarm.watch(eventListener.wrapper)), eventListeners.splice(counter, 1);
      break
     }++counter
    }
   }, Element.prototype.addEventListener = addEventListener, Element.prototype.removeEventListener = removeEventListener))
  }
  var Canadarm = {};
  Canadarm.Appender = {}, Canadarm.Handler = {}, Canadarm.constant = {}, Canadarm.utils = {}, Canadarm.version = "1.1.0.SNAPSHOT", Canadarm.constant.UNKNOWN_LOG = "?";
  var level = {
   FATAL: "FATAL",
   ERROR: "ERROR",
   WARN: "WARN",
   INFO: "INFO",
   DEBUG: "DEBUG"
  };
  Canadarm.level = level, Canadarm.levelOrder = [level.DEBUG, level.INFO, level.WARN, level.ERROR, level.FATAL], "undefined" == typeof Canadarm.levelOrder.indexOf && (Canadarm.levelOrder.indexOf = function(searchElement, fromIndex) {
   var k;
   if (null == this) throw new TypeError('"this" is null or not defined');
   var O = Object(this),
    len = O.length >>> 0;
   if (0 === len) return -1;
   var n = +fromIndex || 0;
   if (Math.abs(n) === 1 / 0 && (n = 0), n >= len) return -1;
   for (k = Math.max(n >= 0 ? n : len - Math.abs(n), 0); len > k;) {
    if (k in O && O[k] === searchElement) return k;
    k++
   }
   return -1
  }), Canadarm.utils.fixedEncodeURIComponent = fixedEncodeURIComponent;
  var errorAppenders = [],
   errorHandlers = [];
  Canadarm.debug = customLogEvent(Canadarm.level.DEBUG), Canadarm.info = customLogEvent(Canadarm.level.INFO), Canadarm.warn = customLogEvent(Canadarm.level.WARN), Canadarm.error = customLogEvent(Canadarm.level.ERROR), Canadarm.fatal = customLogEvent(Canadarm.level.FATAL), Canadarm.addHandler = addHandler, Canadarm.addAppender = addAppender, Canadarm.init = init, Canadarm.loggingLevel = Canadarm.level.DEBUG, Canadarm.Appender.standardLogAppender = standardLogAppender, Canadarm.Handler.beaconLogHandler = beaconLogHandler, Canadarm.Handler.consoleLogHandler = consoleLogHandler, Canadarm.watch = function(fn, context) {
   return _watchFn(fn, context)
  }, Canadarm.attempt = function(fn, context) {
   var args = Array.prototype.slice.call(arguments, 2);
   return _watchFn(fn, context)(args)
  }, Canadarm.localWatch = function(fn, settings, context) {
   return _watchFn(fn, context, settings)
  }, Canadarm.localAttempt = function(fn, settings, context) {
   var args = Array.prototype.slice.call(arguments, 3);
   return _watchFn(fn, context, settings)(args)
  };
  var _oldOnError = window.onerror;
  Canadarm.setUpOnErrorHandler = setUpOnErrorHandler, Canadarm.setUpEventListening = setUpEventListening, window.navigator || (window.navigator = {}), window.location || (window.location = {}), window.document || (window.document = {}), window.navigator || (window.navigator = {}), Canadarm._window = window, "function" == typeof define && define.amd ? (window.Canadarm = Canadarm, define("canadarm", [], function() {
   return Canadarm
  })) : "object" == typeof module ? module.exports = Canadarm : "object" == typeof exports ? exports = Canadarm : window.Canadarm = Canadarm
 }("undefined" != typeof window ? window : this);