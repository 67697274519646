! function(root, factory) {
  if ("function" == typeof define && define.amd) define(["exports", "jquery"], function(exports, $) {
   return factory(exports, $)
  });
  else if ("undefined" != typeof exports) {
   var $ = require("jquery");
   factory(exports, $)
  } else factory(root, root.jQuery || root.Zepto || root.ender || root.$)
 }(this, function(exports, $) {
  function FormSerializer(helper, $form) {
   function build(base, key, value) {
    return base[key] = value, base
   }

   function makeObject(root, value) {
    for (var k, keys = root.match(patterns.key); void 0 !== (k = keys.pop());)
     if (patterns.push.test(k)) {
      var idx = incrementPush(root.replace(/\[\]$/, ""));
      value = build([], idx, value)
     } else patterns.fixed.test(k) ? value = build([], k, value) : patterns.named.test(k) && (value = build({}, k, value));
    return value
   }

   function incrementPush(key) {
    return void 0 === pushes[key] && (pushes[key] = 0), pushes[key]++
   }

   function encode(pair) {
    switch ($('[name="' + pair.name + '"]', $form).attr("type")) {
     case "checkbox":
      return "on" === pair.value ? !0 : pair.value;
     default:
      return pair.value
    }
   }

   function addPair(pair) {
    if (!patterns.validate.test(pair.name)) return this;
    var obj = makeObject(pair.name, encode(pair));
    return data = helper.extend(!0, data, obj), this
   }

   function addPairs(pairs) {
    if (!helper.isArray(pairs)) throw new Error("formSerializer.addPairs expects an Array");
    for (var i = 0, len = pairs.length; len > i; i++) this.addPair(pairs[i]);
    return this
   }

   function serialize() {
    return data
   }

   function serializeJSON() {
    return JSON.stringify(serialize())
   }
   var data = {},
    pushes = {};
   this.addPair = addPair, this.addPairs = addPairs, this.serialize = serialize, this.serializeJSON = serializeJSON
  }
  var patterns = {
   validate: /^[a-z_][a-z0-9_]*(?:\[(?:\d*|[a-z0-9_]+)\])*$/i,
   key: /[a-z0-9_]+|(?=\[\])/gi,
   push: /^$/,
   fixed: /^\d+$/,
   named: /^[a-z0-9_]+$/i
  };
  return FormSerializer.patterns = patterns, FormSerializer.serializeObject = function() {
   return new FormSerializer($, this).addPairs(this.serializeArray()).serialize()
  }, FormSerializer.serializeJSON = function() {
   return new FormSerializer($, this).addPairs(this.serializeArray()).serializeJSON()
  }, "undefined" != typeof $.fn && ($.fn.serializeObject = FormSerializer.serializeObject, $.fn.serializeJSON = FormSerializer.serializeJSON), exports.FormSerializer = FormSerializer, FormSerializer
 });