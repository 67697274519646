require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('select2')

import $  from 'jquery';
import 'bootstrap'
import 'popper.js/dist/popper.js';

import './portal/anchor.js';
import './portal/canadarm.js';
import './portal/galery.js';
import './portal/jquery-modern.js';
import './portal/jquery.serialize-object.js';
import './portal/parallax.js';
import './portal/loader.js';
import './portal/lightbox.js'
import './portal/monotracker';
import './portal/nav.js';
import './portal/touch-events.js';
import './portal/slider.js';
import './portal/scrollTop.js';
import './portal/video.js';
import 'select2/dist/css/select2.css';
import { cocoonInitializer } from 'cocoon/app/assets/javascripts/cocoon';
import modernizr from 'modernizr';

import '../stylesheets/portal';

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="tooltip"]').popover()
  $('.select2').select2()
  $('.dropdown-toggle').dropdown()

  // $("#btn-oportunidades").click(function(event) {
  //   // TODO, adicionar requisição de vagas abertas
  //   // event.preventDefault();
  //   $.ajax({
  //   });
  // });


  $(document).ready(function() {
    $('#vagas_abertas').select2({
      placeholder: "Procurar Vagas",
      allowClear: true
    });
  });

  $(document).ready(function() {
    $('#cidades').select2({
      placeholder: "Procurar Cidades",
      allowClear: true
    });
  });
})