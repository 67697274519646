"use strict";

var _mtr = function(window, document, fns) {
 function init() {
  getObjectsFromDom(), detectCallTracking(window.location.search, document.cookie), replaceCallTracking(), addObjects(), applyFns()
 }

 function detectCallTracking(t, e) {
  var n = t.match(/mono_ctn=([^&]+)/i);
  n ? (ctn = decodeURIComponent(n[1]), document.cookie = "mono_ctn=" + ctn + ";path=/") : (n = e.match(/mono_ctn=([^;]+)/i), n && (ctn = n[1]))
 }

 function replaceCallTracking() {
  if (!ctn) return !1;
  for (var t = 0; t < phoneObjects.length; t++)
   if ("a" == phoneObjects[t].tagName.toLowerCase())
    if (0 === phoneObjects[t].getAttribute("href").indexOf("tel")) {
     var e = phoneObjects[t].getAttribute("href").replace("tel:", "").split("").map(function(t) {
       return t.replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")
      }),
      n = new RegExp(e.join("\\s*"), "g");
     n.test(phoneObjects[t].innerHTML) && (phoneObjects[t].innerHTML = phoneObjects[t].innerHTML.replace(n, ctn)), phoneObjects[t].setAttribute("href", "tel:" + ctn.replace(/[- .\(\)]+/, ""))
    } else phoneObjects[t].innerHTML = ctn;
  else phoneObjects[t].innerHTML = ctn
 }

 function addObjects() {
  for (var t = 0; t < trackObjects.length; t++) addObject(trackObjects[t])
 }

 function getObjectsFromDom() {
  if (document.querySelectorAll) trackObjects = document.body.querySelectorAll("[data-track-event][data-track-action]"), phoneObjects = document.body.querySelectorAll('[data-global="phone"]');
  else {
   trackObjects = [], phoneObjects = [];
   for (var t = document.body.getElementsByTagName("*"), e = 0; e < t.length; e++) t[e].getAttribute("data-track-event") && t[e].getAttribute("data-track-action") && trackObjects.push(t[e]), "phone" == t[e].getAttribute("data-global") && phoneObjects.push(t[e])
  }
 }

 function addEvent(t, e, n) {
  t.addEventListener ? t.addEventListener(e, n, !1) : t.attachEvent && t.attachEvent("on" + e, n)
 }

 function addObject(t, e, n) {
  if (!t) return !1;
  if (!e && t.getAttribute("data-track-event") && (e = t.getAttribute("data-track-event")), !n && t.getAttribute("data-track-action") && (n = t.getAttribute("data-track-action")), !e || !n) return !1;
  e = e.split(" ");
  for (var a = 0; a < e.length; a++) addEvent(t, e[a].replace(/^\s+|\s$/, ""), function() {
   track(n, t)
  });
  return t
 }

 function addTracker(t) {
  return "function" != typeof t ? !1 : (trackers.push(t), !0)
 }

 function addRawTracker(t) {
  return "function" != typeof t ? !1 : (rawTrackers.push(t), !0)
 }

 function setData(t, e) {
  return "string" != typeof t ? !1 : (dataObjects[t] = e, !0)
 }

 function getData(t) {
  return "string" != typeof t ? !1 : "undefined" == typeof dataObjects[t] ? !1 : dataObjects[t]
 }

 function track(t, e) {
  if (e = e || !1, "string" != typeof t) return !1;
  for (var n = 0; n < trackers.length; n++) trackers[n](t, e);
  return !0
 }

 function raw(t, e) {
  for (var n = 0; n < rawTrackers.length; n++) rawTrackers[n](t, e)
 }

 function push(t) {
  var e, n = [];
  for (e = 1; e < t.length; e++) n.push(t[e]);
  _mtr[t[0]].apply(_mtr, n)
 }

 function applyFns() {
  var t, e, n = [];
  if (fns && fns.length)
   for (; t = fns.shift();) {
    for (n = [], e = 1; e < t.length; e++) n.push(t[e]);
    _mtr[t[0]].apply(_mtr, n)
   }
 }

 function _test(expr) {
  return eval(expr)
 }
 var rawTrackers = [],
  trackers = [],
  trackObjects = [],
  phoneObjects = [],
  dataObjects = {},
  ctn = !1;
 return {
  addObject: addObject,
  addTracker: addTracker,
  addRawTracker: addRawTracker,
  setData: setData,
  getData: getData,
  detectCallTracking: detectCallTracking,
  track: track,
  raw: raw,
  init: init,
  push: push,
  _test: _test
 }
}(window, document, _mtr);
_mtr.init();