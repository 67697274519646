"use strict";
import modernizr from 'modernizr';

!function (i, t, e, a) {
	var o = function (t, o) {
		var s = {};
		this.options = i.extend({}, s, o), this.$element = i(t), this.$window = i(a), this.$body = i(e.body), this.$backdrop = void 0, this.$lightbox = void 0, this.$loader = void 0, this.$ui = void 0, this.$btnClose = void 0, this.loading = void 0, this.slides = [], this.slideCount = 0, this.aspectRatio = void 0, this.clickEvent = "click", this.noCaption = !1, this.tabbableElements = i("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]"), this.init()
	};
	o.prototype = {
		init: function () {
			var i = this,
				e = i.$element.find("a").has("img");
			t.touchevents && (i.clickEvent = "click tap"), this.$element.hasClass("gallery") && this.$element.attr("data-settings").toLowerCase().indexOf("captioninpopup=false") > -1 && (this.noCaption = !0), e.each(function () {
				i.slides.push({
					src: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D",
					fullSrc: this.href,
					orientation: "",
					caption: this.title
				})
			}), i.slideCount = i.slides.length - 1, i.$element.on(i.clickEvent, "a:has(img)", function (t) {
				t.preventDefault();
				for (var e = 0; e < i.slideCount && i.slides[e].fullSrc !== this.href; e++);
				i.open(e)
			}), i.buildHTML(), i.$window.on("load", function () {
				i.setImageAspectRatio(), i.setImageClasses()
			})
		},
		open: function (o) {
			var s = this;
			s.$body.addClass("hide-overflow"), s.$body.append(s.$backdrop), s.$body.append(s.$lightbox), s.$lightbox.imgSlider({
				container: ".lightbox-container",
				slides: ".lightbox-image",
				previous: ".prev",
				next: ".next",
				index: o,
				keyboard: !t.touchevents,
				onChange: function () {
					s.loadImage(this.currentSlide)
				}
			}), a._mtr && a._mtr.track("gallery_overlay_open"), s.loadImage(o), s.setAspectRatio(), s.setImageClasses(), s.$btnClose.on(s.clickEvent, function (t) {
				if (void 0 !== i("body").attr("data-enablefocusindication") && s.$element.data().monogallery) {
					var e = s.$lightbox.data().slider.currentSlide,
						a = s.$element.data("settings"),
						o = s.$element.data().monogallery.viewport;
					o = "lg" === o ? "" : "-" + o;
					var l = new RegExp("imagecols" + o + "=(\\d+)", "i"),
						n = a.match(l) ? parseInt(a.match(l)[1]) : 1;
					s.$element.data().monogallery.changeSlide(Math.floor(e / n), !1)
				}
				s.close(), t.stopPropagation(), t.preventDefault()
			}), void 0 !== i("body").attr("data-enablefocusindication") && this.trapFocus(i(".lightbox")), t.touchevents ? (s.$ui.addClass("hidden-small"), s.$lightbox.on(s.clickEvent, function () {
				s.$ui.toggleClass("hidden-small")
			})) : i(e).on("keydown", function (i) {
				27 === i.keyCode && (s.close(), s.$element.find("a")[o].focus(), i.preventDefault())
			}), s.$window.on("resize.lightbox", i.proxy(s.setAspectRatio, s)), s.$window.on("resize.lightbox", i.proxy(s.setImageClasses, s)), this.toggleTabbingOnPage(!1), this.enableTabbingOnLightbox()
		},
		close: function () {
			this.$backdrop.remove(), this.$lightbox.remove(), this.$body.removeClass("hide-overflow"), this.$window.off("resize.lightbox", i.proxy(self.setAspectRatio, self)), this.$window.off("resize.lightbox", i.proxy(self.setImageClasses, self)), this.toggleTabbingOnPage(!0)
		},
		trapFocus: function (i) {
			var t = i.find("a[href]"),
				a = t[0],
				o = t[t.length - 1],
				s = 9;
			i.on("keydown", function (i) {
				var t = "Tab" === i.key || i.keyCode === s;
				t && (i.shiftKey ? e.activeElement === a && (o.focus(), i.preventDefault()) : e.activeElement === o && (a.focus(), i.preventDefault()))
			})
		},
		buildHTML: function () {
			for (var t = this, e = "", a = 0; a < t.slideCount + 1; a++) e += '<div class="lightbox-image" aria-hidden = "true">', e += '<img src="' + t.slides[a].src + '" alt="">', t.noCaption || (e += '<div class="lightbox-caption">' + t.slides[a].caption + "</div>"), e += "</div>";
			t.$backdrop = i('<div class="lightbox-backdrop"></div>'), t.$lightbox = i('<div class="lightbox" aria-modal="true" role="dialog" aria-label="Gallery"><div class="lightbox-container">' + e + "</div></div>"), t.$loader = i('<div class="lightbox-loading hidden"></div>'), t.$ui = i('<div class="lightbox-ui"></div>'), t.$btnClose = i('<a class="close" aria-label="close" href="#"></a>'), t.$lightbox.append(t.$loader), t.$lightbox.append(t.$ui), t.$ui.append(t.$btnClose), t.slideCount > 0 && t.$ui.append(i('<a class="prev" aria-label="previous" href="#"></a><a class="next" aria-label="next" href="#"></a>'))
		},
		startLoader: function () {
			var i = this,
				t = 0;
			i.loading || (i.loading = setInterval(function () {
				t = t > -352 ? t - 32 : 0, i.$loader[0].style.backgroundPosition = "0 " + t + "px"
			}, 100), i.$loader.removeClass("hidden"))
		},
		stopLoader: function () {
			this.$loader.addClass("hidden"), clearInterval(this.loading), this.loading = void 0
		},
		loadImage: function (t) {
			for (var e = this, a = e.$lightbox.find(".lightbox-image").eq(t), o = a.find("img")[0], s = 0; s < e.slideCount + 1; s++) e.$lightbox.find(".lightbox-image").eq(s).attr("aria-hidden", "true");
			return a.attr("aria-hidden", "false"), a.attr("aria-live", "polite"), o.src === e.slides[t].fullSrc ? void a.addClass("lightbox-image-loaded") : (e.startLoader(), void i("<img>").attr("src", e.slides[t].fullSrc).on("load", function () {
				this.src === e.slides[t].fullSrc && (a.addClass("lightbox-image-loaded"), o.src = e.slides[t].fullSrc, e.stopLoader())
			}))
		},
		setAspectRatio: function () {
			this.aspectRatio = this.$lightbox.width() / this.$lightbox.height()
		},
		setImageAspectRatio: function () {
			for (var i = 0; i < this.slideCount; i++) {
				var t = this.$element.find("img").eq(i);
				this.slides[i].aspectRatio = t.width() / t.height()
			}
		},
		setImageClasses: function () {
			for (var i, t, e = 0; e < this.slideCount + 1; e++) i = this.aspectRatio > this.slides[e].aspectRatio ? "lightbox-image-tall" : "lightbox-image-wide", t = "lightbox-image-wide" === i ? "lightbox-image-tall" : "lightbox-image-wide", this.$lightbox.find(".lightbox-image").eq(e).removeClass(t).addClass(i)
		},
		toggleTabbingOnPage: function (t) {
			var e = t ? "0" : "-1";
			i.each(this.tabbableElements, function (t, a) {
				i(a).attr("tabindex", e)
			})
		},
		enableTabbingOnLightbox: function () {
			i(".lightbox-ui").find(".close, .prev, .next").attr("tabindex", 0)
		}
	}, i.fn.lightbox = function (t) {
		return this.each(function () {
			i.data(this, "lightbox") || i.data(this, "lightbox", new o(this, t))
		})
	}, i(function () {
		i('[data-req*="lightbox"]').lightbox()
	})
}(jQuery, modernizr, document, window);